import React from 'react'

const Intro = ({bookingUrl, buttonStyle, style}) => {
    return (
        <React.Fragment>
            <div className="row justify-content-center">
                <div className="col-12" style={{maxWidth: style.maxWidth}}>
                    <div className="row justify-content-center">
                        <div className='col-12 d-md-none text-center'>
                            <h2>Meet Erica</h2>
                        </div>
                        <div className='col-12 col-md-4 p-0 text-center'>
                            <img
                                src="https://ac-massage-simpson-erica.s3.us-east-2.amazonaws.com/erica-professional.JPG"
                                alt=""
                                style={{
                                    width: '100%',
                                    // height: '100%',
                                    maxWidth: '269.5px',
                                    maxHeight: '400px'
                                }}
                            />
                        </div>
                        <div className='col text-center'>
                            <h2 className='d-none d-md-inline-block'>Meet Erica</h2>
                            <p className='mt-2 ml-2 ml-md-0 text-left'>
                                Erica is high energy, passionate, and self-described as a “search and destroy” type
                                massage therapist. She excels with rehabilitative and chronic pain management,
                                incorporating lots of Trigger Point and Myofascial Release techniques in her work. As
                                Anne’s daughter, massage became a part of her life at a very young age, and as time
                                progressed she realized providing relief of the highest quality was much more than a fun
                                activity to do with friends, but something she wanted to provide for as many people as
                                possible. Erica’s creative approach stems from her love of and aptitude for learning and
                                thinking outside the box, and the results speak for themselves. Clients report finding
                                relief they’ve been searching for, sometimes for years. Erica uses all of her boundless
                                energy and positive essence to shepherd you, musculature and Self, to your personal
                                goals, finding opportunity for additional release and relief all along the way.
                            </p>
                            <a target="_blank"
                               rel="noopener noreferrer"
                               className={`btn mt-md-2 ${buttonStyle || 'btn-success '}`}
                               style={buttonStyle}
                               href={bookingUrl}>
                                <u>Book with Erica</u>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5" style={{maxWidth: style.maxWidth}}>
                    <div className="row justify-content-center">
                        <div className='col-12 col-md-4 p-0 text-center'>
                            <img
                                src="https://ac-massage-simpson-erica.s3.us-east-2.amazonaws.com/anne-professional.jpg"
                                alt=""
                                style={{
                                    width: '100%',
                                    // height: '100%',
                                    maxWidth: '269.5px',
                                    maxHeight: '400px'
                                }}
                            />
                        </div>
                        <div className='col text-center'>
                            <h2 className='d-none d-md-inline-block'>Meet Anne</h2>
                            <p className='mt-2 ml-2 ml-md-0 text-left'>
                                Anne brings the essence of ease, peace, and steady reliability to each of her massage
                                treatments. Time spent in her care is expertly crafted to utilize all of her over two
                                decades of skill and knowledge to fine tune your treatment to leave you as relaxed,
                                revitalized, and renewed as possible. As an active person and a parent, she knows from
                                her own experience how vital life without pain and restriction is and how challenging it
                                can be to achieve, especially on your own. When you’re in Anne’s hands, the first thing
                                that is apparent is that she’s on your side and will do everything in her power to
                                remind you- mind and tissue- that she’s here so you can let go and let her take over.
                                With additional training in Shamanic Energy Medicine, Sound Healing, Reiki, and
                                Hypnotherapy, Anne’s passion for bringing wellness of the deepest level to her clients
                                shines brilliantly during and long after each of her treatments.
                            </p>
                            <a target="_blank"
                               rel="noopener noreferrer"
                               className={`btn mt-md-2 ${buttonStyle || 'btn-success '}`}
                               style={buttonStyle}
                               href={bookingUrl}>
                                <u>Book with Anne</u>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Intro
