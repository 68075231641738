import React from 'react'
import TopNav from './TopNav'
import {resourceHeight} from "../util";

export default class Map extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row" style={{marginBottom: '-5px'}}>
                <div className='col-12 p-0' id='map' style={{
                    minHeight: this.props.style.minHeight,
                    maxHeight: this.props.style.maxHeight,
                }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.796400838808!2d-71.55745332198717!3d42.34686397119424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e38b4474c81157%3A0xd1c32204d469cd7b!2s40%20Mechanic%20St%20%23110%2C%20Marlborough%2C%20MA%2001752!5e0!3m2!1sen!2sus!4v1736264920576!5m2!1sen!2sus"
                        width='100%'
                        height={this.props.style.minHeight}
                        frameBorder="0"
                        allowFullScreen="">
                    </iframe>
                </div>
            </div>
        )
    }
}