import React from 'react'
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default class Contact extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { address, email, phone,firstName, lastName, title} = this.props
        return (
            <div className="row align-items-center justify-content-center contact" style={this.props.style}>
                <div className='col-12 text-center' id='contact'>
                    <h2 className="has-text-align-center">
                        <MailOutlineIcon fontSize='large' className='mr-1'/>
                        Contact
                    </h2>
                    <p className='mt-4'>
                        {`${firstName} ${lastName}`}
                    </p>
                    <p>
                    {/*<strong>Address:</strong>*/}
                        {/*<br/>*/}
                        {address}
                    </p>
                    <p>
                        {/*<strong>Email:</strong>*/}
                        {/*<br/>*/}
                        {email}
                    </p>
                    <p>
                        {/*<strong>Phone:</strong>*/}
                        {/*<br/>*/}
                        {phone}
                    </p>
                    <a target="_blank"
                       rel="noopener noreferrer"
                       className={`btn mt-md-4 ${this.props.buttonStyle || 'btn-success '}`}
                       href=''
                       style={this.props.buttonStyle}
                    >
                        <strong><u>Book An Appointment</u></strong>
                    </a>
                </div>
            </div>
        )
    }
}
