import React from 'react'
import Divider from '@material-ui/core/Divider';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {resourceHeight} from '../util'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

const sectionColWidth = 10
export default class Videos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            video: null,
            categories: null
        }
    }

    divider(sectionColWidth) {
        return (
            <div className='video row justify-content-center'>
                <div className={`col-12 col-md-${sectionColWidth} pt-2 pb-2 pt-md-4 pb-md-4 p-0`}>
                    <Divider variant="middle" className='ml-0 mr-0'/>
                </div>
            </div>
        )
    }

    render() {
        const { bottomDivider } = this.props
        return (
            <div id='videos' className='section-color'>
                <div className="row justify-content-center video">
                    <div className="col-12 col-md-6 text-center">
                        <h2 className="has-text-align-center">
                            <OndemandVideoIcon fontSize='large' className='mr-1'/>
                            Videos
                        </h2>
                        <p className='mt-4'>Stretching and strengthening tips for current patients or the general public interested in maintaining
                            healthy
                            bodies.</p>
                    </div>
                </div>
                {this.divider(sectionColWidth)}
                <div className='video row justify-content-center pb-3 pb-md-0'>
                    <div className={`col-12`}>
                        <div className="row justify-content-center text-right">
                            {
                                this.props.videoNav.map((section) => {
                                    return (
                                        <div key={section.title} className={`col-12 col-md-5 mt-3 mt-lg-0 text-center ${section.paddings}`}>
                                            <div style={{
                                                height: `${resourceHeight(2, sectionColWidth, 0.33)}vw`,
                                                minHeight: '256px',
                                                // backgroundImage: `url(${section.url})`,
                                                backgroundImage: `linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .35)), url(${section.url})`,
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: section.imagePosition,
                                                borderRadius: '25px'

                                            }}>
                                                {/*<div style={section.style}/>*/}
                                                <Link to={section.page}>
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        className='h-100 w-100 reactive-btn'
                                                        style={{borderRadius: '25px'}}
                                                    >
                                                    <span className='reactive-btn-title'
                                                          style={{
                                                              position: 'absolute',
                                                              bottom: '20px',
                                                          }}>
                                                        {section.title}
                                                    </span>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {this.divider(sectionColWidth)}
            </div>
        )
    }
}
