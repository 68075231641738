import React from 'react'
import {withRouter} from 'react-router-dom'
import TopNav from '../shared/TopNav'
import TagLineFullWidth from '../shared/TagLineFullWidth'
import Intro from '../shared/Intro'
import Contact from '../shared/Contact'
import Videos from '../shared/Videos'
import Footer from '../shared/Footer'
import Content1 from '../shared/Content1'
import Map from '../shared/Map'
import SellingPoints from '../shared/SellingPoints'

const maxWidth = '1675px'
const height = window.innerHeight / 3
const minHeight = 300
const maxHeight = minHeight * 2.5

const videoNav = [
    {
        url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/stretching_woman.jpg',
        title: 'Watch All Videos',
        imagePosition: 'center center',
        style: {
            // position: 'absolute',
            top: '0',
            bottom: '0',
            right: '15px',
            left: '15px',
            backgroundColor: 'black',
            opacity: '0.33',
            borderRadius: '25px',
        },
        page: '/videos'
    },
    {
        url: 'https://ac-hu-sonya.s3-us-west-1.amazonaws.com/girl-dancing.jpg',
        title: 'Watch by Topics',
        imagePosition: null,
        style: {
            // position: 'absolute',
            top: '0',
            bottom: '0',
            right: '15px',
            left: '15px',
            backgroundColor: 'black',
            opacity: '0.33',
            borderRadius: '25px',
        },
        page: '/topics'
    },
]

const IndexView = (props) => {
    
    const getPxHeight = (height) => {
        return (`${height}px`)
    }
    
    const pxHeight = getPxHeight(height)
    const pxMinHeight = getPxHeight(minHeight)
    const pxMaxHeight = getPxHeight(maxHeight)
    const taglineMaxHeight = window.outerHeight * 0.666
    
    return (
        <div className='container-fluid' style={{color: '#787878', maxWidth: maxWidth}}>
            <TopNav {...props}/>
            <div className='section pt-0'>
                <TagLineFullWidth
                    {...props}
                    image={props.image}
                    backgroundPosition={props.backgroundPosition}
                    style={{
                        height: getPxHeight(window.innerHeight),
                        maxHeight: getPxHeight(taglineMaxHeight > maxHeight ? maxHeight : taglineMaxHeight)
                    }}/>
                    <div id='about'></div>
            </div>
            <div className='section'>
                <Intro {...props} style={{
                    maxWidth: maxWidth,
                }}/>
            </div>
            <div className='section'>
                <SellingPoints {...props}/>
            </div>
            <div className='section'>
                <Content1 {...props} style={{
                    height: getPxHeight(window.innerHeight / 2),
                    minHeight: minHeight,
                    maxHeight: pxMaxHeight
                }}/>
            </div>
            {/*<div className='section'>*/}
            {/*    <Videos videoNav={videoNav}/>*/}
            {/*    <div id='contact'/>*/}
            {/*</div>*/}
            <div style={{paddingBottom: '64px'}}>
                <Contact {...props} />
            </div>
            <div className='section pb-0'>
                <Map {...props} style={{
                    height: pxHeight,
                    minHeight: pxMinHeight,
                    maxHeight: pxMaxHeight
                }}/>
            </div>
            <div className='section pb-0'>
                <Footer
                    {...props}
                    filter={['map', 'videos', 'contact']}
                    style={{
                        backgroundColor: '#343a40',
                    }}
                />
            </div>
        </div>
    )
}

export default withRouter(IndexView)
